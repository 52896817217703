import {Component, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import { MessagingService } from 'donor-tracking-library';
import { Location } from '@angular/common';
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {AuthService} from "../../auth/auth.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnInit, OnDestroy {

  donor_id;
  donor_user_id;
  donor_display_name;
  user_display_name: string;
  user_id: number;

  @ViewChild("msgInput") msgInput: ElementRef;

  isReconnecting$;

  refreshSubscription: Subscription;

  constructor(private messageService: MessagingService, private route: ActivatedRoute,
              private authService: AuthService, private location: Location, private router: Router) {

    // Workaround to make router.navigate with the same url refreshes the component:
    // https://github.com/angular/angular/issues/13831#issuecomment-319634921

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.refreshSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {
    this.isReconnecting$ = this.messageService.isWebSocketReconnecting;
    this.donor_id = this.route.snapshot.paramMap.get('donor_id');
    this.donor_user_id = this.route.snapshot.paramMap.get('donor_user_id');
    this.donor_display_name= this.route.snapshot.queryParamMap.get('donor_display_name');
    // TODO: make profile service and use it for display name
    this.user_display_name = this.authService.userData.display_name;
    this.user_id = this.authService.userData.user_id;
  }


  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }

  goBack(): void {
    this.location.back();
  }

  refresh() {
    this.router.navigateByUrl(this.router.url);
  }

}
