export interface ILink {
  link: string | string[];
  icon?: string;
  text: string;
  permitted_roles: string[];
  childs?: ILink[];
}


export const sidenavLinks: ILink[] = [
  {
    link: ['/donor-list'],
    text: 'Donors',
    icon: 'group',
    permitted_roles: ['clinic_admin', 'doctor', 'nurse', 'assistant']
  },
  {
    link: ['/nurse-list'],
    text: 'Nurses',
    icon: 'group',
    permitted_roles: ['clinic_admin']
  },
  {
    link: ['/assistant-list'],
    text: 'Assistants',
    icon: 'group',
    permitted_roles: ['clinic_admin', 'doctor', 'nurse']
  },
  {
    link: ['/doctor-list'],
    text: 'Doctors',
    icon: 'group',
    permitted_roles: ['clinic_admin']
  },
  {
    link: ['/reminder-list'],
    text: 'Automatic Reminders',
    icon: 'notification_important',
    permitted_roles: ['clinic_admin', 'doctor', 'nurse', 'assistant']
  },
  {
    link: ['/report'],
    text: 'Reports',
    icon: 'print',
    permitted_roles: ['clinic_admin', 'doctor', 'nurse', 'assistant']
  }
];
