import { Component, OnInit } from '@angular/core';
import {StaffService} from "../staff.service";
import {Observable} from "rxjs";
import {Doctor} from "donor-tracking-library";

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.scss']
})
export class DoctorListComponent implements OnInit {

  doctors$: Observable<Doctor[]>;
  tableColumns: string[] = ['display_name', 'email'];

  constructor(private staffService: StaffService) { }

  ngOnInit() {
    this.doctors$ = this.staffService.clinicDoctorsList();
  }


}
