import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {apiURL, donorDetailsFullUrlMissingDonorId} from "../settings";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DonorService {

  constructor(private http: HttpClient) { }

  list(offset=0, filters=null, sortObject=null): Observable<any> {
    let donorListUrl = `${apiURL}/donor/list/?limit=100&offset=${offset}`;
    if(sortObject) {
      donorListUrl = sortObject.active? donorListUrl + `&order_by=${sortObject.active}` : donorListUrl;
      donorListUrl = sortObject.direction? donorListUrl + `&order_direction=${sortObject.direction}` : donorListUrl;
    }
    if(typeof filters === 'object' && filters !== null)
      Object.keys(filters).map((key) => {
        if(filters[key] && typeof filters[key] === 'string')
          donorListUrl = donorListUrl + `&${key}=${filters[key]}`
      });
    return this.http.get(donorListUrl)
      .pipe(map((resp: any) => {
        return resp.results;
      }))
  }

  details(id): Observable<any> {
    const donorDetailsUrl = `${apiURL}/donor/${id}/`;
    return this.http.get(donorDetailsUrl)
  }

  addNewDonor(donor): Observable<any> {
    const addDonorUrl = `${apiURL}/donor/`;
    return this.http.post(addDonorUrl, donor);
  }

  assignNurseToDonor(donor_id, nurse_id) {
    return this.updateDonor(donor_id, {nurse: nurse_id})
  }

  assignDoctorToDonor(donor_id, doctor_id) {
    return this.updateDonor(donor_id, {doctor: doctor_id})
  }

  assignAssistantToDonor(donor_id, assistant_id) {
    return this.updateDonor(donor_id, {assistant: assistant_id})
  }

  changeDonorStatus(donor_id, new_status) {
    return this.updateDonor(donor_id, {status: new_status})
  }

  changeBloodTestStatus(donor_id, new_status) {
    return this.updateDonor(donor_id, {blood_test_status: new_status})
  }

  updateDonor(donor_id, updated_data): Observable<any> {
    const updateDonorUrl = `${apiURL}/donor/${donor_id}/`;
    return this.http.patch(updateDonorUrl, updated_data);
  }

  addNote(donor_id, note_content, notifiedUserId=null) {
    // url for the note that will be sent to the notified user (staff), missing id of the note that is not created yet
    const createdNoteUrlMissingNoteId = `${donorDetailsFullUrlMissingDonorId}${donor_id}#note`;
    const noteAddUrl = `${apiURL}/donor/note/`;
    return this.http.post(noteAddUrl, {donor_id: donor_id, content: note_content, receiver: notifiedUserId,
      note_url: createdNoteUrlMissingNoteId})
  }

  updateNote(note_id, note_content) {
    const noteAddUrl = `${apiURL}/donor/note/${note_id}/`;
    return this.http.patch(noteAddUrl, {content: note_content})
  }

}
