import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Location } from '@angular/common';
import {StaffService} from "../staff.service";
import {FormBuilder, Validators} from "@angular/forms";
import {passwordMinLength} from "../../settings";

@Component({
  selector: 'app-nurse-create-form',
  templateUrl: './nurse-create-form.component.html',
  styleUrls: ['./nurse-create-form.component.scss']
})
export class NurseCreateFormComponent {

  creationLoading: boolean;
  creationError: boolean;
  creationErrorObject: any;


  nurseForm = this.fb.group({
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    user: this.fb.group({
      password: ['', [Validators.required, Validators.minLength(passwordMinLength)]],
      email: ['', [Validators.email, Validators.required]],
    })
  });


  constructor(private staffService: StaffService,
              private snackBar: MatSnackBar,
              private location: Location,
              private fb: FormBuilder) {

  }

  create(){
    this.creationError = false;
    this.creationErrorObject = null;
    this.creationLoading = true;
    this.staffService.addNewNurse(this.nurseForm.value)
      .subscribe(
        (res) => {
          this.creationLoading = false;
          this.openSnackBar('Nurse created successfully!', 'OK')
        },
        (errorRes) => {
          this.creationLoading = false;
          this.creationError = true;
          this.creationErrorObject = errorRes.error;
        }
      )
  }

  cancel(){
    this.location.back();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    }).afterDismissed().subscribe(() => {
      this.location.back();
    });
  }
}
