import { Component } from '@angular/core';
import {BLOOD_TEST_STATUS_CHOICES, ORGAN_CHOICES, Donor} from "donor-tracking-library";
import {FormBuilder} from "@angular/forms";
import {MatDialog} from '@angular/material/dialog';
import {DonorService} from "../donor/donor.service";
import {ReportModalComponent} from "./report-modal/report-modal.component";


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {

  creationError;
  creationLoading;

  ORGAN_CHOICES = ORGAN_CHOICES;

  BLOOD_STATUS_CHOICES = BLOOD_TEST_STATUS_CHOICES;

  animal: string;
  name: string;

  reportForm = this.fb.group({
    organ: ['', []],
    blood_test_status: ['', []],
    days: ['', []],
    days_max: ['', []],
    days_min: ['', []],
  });

  constructor(private fb: FormBuilder, public dialog: MatDialog, private donorService: DonorService) { }

  loadReport() {
    this.creationError = false;
    this.creationLoading = true;
    let formValue = this.reportForm.value;
    console.log(formValue);
    if(formValue.days)
      formValue.days = formValue.days.toString();
    if(formValue.days_min)
      formValue.days_min = formValue.days_min.toString();
    if(formValue.days_max)
      formValue.days_max = formValue.days_max.toString();

    this.donorService.list(0, formValue)
      .subscribe((donors: Donor[]) => {
        this.creationLoading = false;
        this.openDialog(donors);
      });
  }

  openDialog(donors: Donor[]): void {
    const dialogRef = this.dialog.open(ReportModalComponent, {
      width: '80%',
      data: donors
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }

  disableOthers() {
    if(this.reportForm.controls['days'].value) {
      this.reportForm.controls['days_min'].disable();
      this.reportForm.controls['days_max'].disable();
    }
    else {
      this.reportForm.controls['days_min'].enable();
      this.reportForm.controls['days_max'].enable();
    }
  }

}
