import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "./auth/login/login.component";
import {DonorListComponent} from "./donor/donor-list/donor-list.component";
import {LayoutComponent} from "./layout/layout/layout.component";
import {AuthGuard} from "./auth/auth.guard";
import {DoctorCreateFormComponent} from "./staff/doctor-create-form/doctor-create-form.component";
import {LayoutLoginComponent} from "./layout/layout-login/layout-login.component";
import {NurseCreateFormComponent} from "./staff/nurse-create-form/nurse-create-form.component";
import {DonorCreateFormComponent} from "./donor/donor-create-form/donor-create-form.component";
import {DonorDetailsComponent} from "./donor/donor-details/donor-details.component";
import {AuthenticatedGuard} from "./auth/authenticated.guard";
import {MessagingComponent} from "./message/messaging/messaging.component";
import {DoctorListComponent} from "./staff/doctor-list/doctor-list.component";
import {DoctorDetailsComponent} from "./staff/doctor-details/doctor-details.component";
import {NurseListComponent} from "./staff/nurse-list/nurse-list.component";
import {NurseDetailsComponent} from "./staff/nurse-details/nurse-details.component";
import {ForgotPasswordComponent} from "./auth/forgot-password/forgot-password.component";
import {ProfileEditComponent} from "./staff/profile-edit/profile-edit.component";
import {AssistantListComponent} from "./staff/assistant-list/assistant-list.component";
import {AssistantDetailsComponent} from "./staff/assistant-details/assistant-details.component";
import {AssistantFormComponent} from "./staff/assistant-form/assistant-form.component";
import {DaysSinceTriggerFormComponent} from "./action-trigger/days-since-trigger-form/days-since-trigger-form.component";
import {DaysSinceTriggerListComponent} from "./action-trigger/days-since-trigger-list/days-since-trigger-list.component";
import {ReportComponent} from "./report/report.component";

const routes: Routes = [
  {
    path: '',
    component: LayoutLoginComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthenticatedGuard],
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [AuthenticatedGuard]
      }
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'donor-list',
        component: DonorListComponent,
      },
      {
        path: 'doctor-list',
        component: DoctorListComponent,
      },
      {
        path: 'doctor-details',
        component: DoctorDetailsComponent,
      },
      {
        path: 'doctor-add',
        component: DoctorCreateFormComponent,
      },
      {
        path: 'nurse-list',
        component: NurseListComponent,
      },
      {
        path: 'nurse-details',
        component: NurseDetailsComponent,
      },
      {
        path: 'nurse-add',
        component: NurseCreateFormComponent,
      },
      {
        path: 'assistant-list',
        component: AssistantListComponent,
      },
      {
        path: 'assistant-details',
        component: AssistantDetailsComponent,
      },
      {
        path: 'assistant-add',
        component: AssistantFormComponent,
      },
      {
        path: 'donor-add',
        component: DonorCreateFormComponent,
      },
      {
        path: 'donor-update/:id',
        component: DonorCreateFormComponent,
      },
      { path: 'donor-detail/:id',
        component: DonorDetailsComponent,
      },
      {
        path: 'reminder-list',
        component: DaysSinceTriggerListComponent,
      },
      {
        path: 'days-since-trigger-add',
        component: DaysSinceTriggerFormComponent,
      },
      {
        path: 'days-since-trigger-update/:id',
        component: DaysSinceTriggerFormComponent,
      },
      { path: 'contact-donor/:donor_id/:donor_user_id',
        component: MessagingComponent,
      },
      {
        path: 'profile-edit',
        component: ProfileEditComponent
      },
      {
        path: 'report',
        component: ReportComponent
      },
      {path: '**', redirectTo: '/login'},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
