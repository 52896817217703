import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import {
  CanActivate,
  Router,
} from '@angular/router';
import {Location} from '@angular/common';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private location: Location) {}

  canActivate(): Observable<boolean> {
    return this.authService.loggedInBefore().pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          this.authService.doUnauthorizedStuff(this.location.path(true));
        }
        return isLoggedIn;
      })
    );
  }
}
