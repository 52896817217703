import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { DonorListComponent } from './donor/donor-list/donor-list.component';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthInterceptor} from "./auth/auth.interceptor";
import {MaterialImportsModule} from "./material-imports/material-imports.module";
import {LayoutModule} from "./layout/layout.module";
import {ErrorInterceptor} from "./auth/error-interceptor";
import { DoctorCreateFormComponent } from './staff/doctor-create-form/doctor-create-form.component';
import { NurseCreateFormComponent } from './staff/nurse-create-form/nurse-create-form.component';
import { LayoutLoginComponent } from './layout/layout-login/layout-login.component';
import { DonorCreateFormComponent } from './donor/donor-create-form/donor-create-form.component';
import { DonorDetailsComponent } from './donor/donor-details/donor-details.component';
import {SelectModule} from 'ng2-select';
import {MessagingComponent} from './message/messaging/messaging.component';
import {DonorTrackingLibraryModule, MessagingModule} from "donor-tracking-library";
import {FlexLayoutModule} from "@angular/flex-layout";
import { DoctorListComponent } from './staff/doctor-list/doctor-list.component';
import { NurseListComponent } from './staff/nurse-list/nurse-list.component';
import { DoctorDetailsComponent } from './staff/doctor-details/doctor-details.component';
import { NurseDetailsComponent } from './staff/nurse-details/nurse-details.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ProfileEditComponent } from './staff/profile-edit/profile-edit.component';
import { AssistantFormComponent } from './staff/assistant-form/assistant-form.component';
import { AssistantListComponent } from './staff/assistant-list/assistant-list.component';
import { AssistantDetailsComponent } from './staff/assistant-details/assistant-details.component';
import {NgxMaskModule} from 'ngx-mask';
import {DaysSinceTriggerFormComponent} from "./action-trigger/days-since-trigger-form/days-since-trigger-form.component";
import { DaysSinceTriggerListComponent } from './action-trigger/days-since-trigger-list/days-since-trigger-list.component';
import {ReportComponent} from './report/report.component';
import {ReportModalComponent} from "./report/report-modal/report-modal.component";
import {apiURL, wsURL} from "./settings";
import {AuthService} from "./auth/auth.service";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DonorListComponent,
    DoctorCreateFormComponent,
    NurseCreateFormComponent,
    LayoutLoginComponent,
    DonorCreateFormComponent,
    DonorDetailsComponent,
    MessagingComponent,
    DoctorListComponent,
    NurseListComponent,
    DoctorDetailsComponent,
    NurseDetailsComponent,
    ForgotPasswordComponent,
    ProfileEditComponent,
    AssistantFormComponent,
    AssistantListComponent,
    AssistantDetailsComponent,
    DaysSinceTriggerFormComponent,
    DaysSinceTriggerListComponent,
    ReportComponent,
    ReportModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportsModule,
    LayoutModule,
    SelectModule,
    DonorTrackingLibraryModule,
    FlexLayoutModule,
    MessagingModule.forRoot({
      messages_url: `${apiURL}/message/`,
      messages_page_size: 40,
      auth: AuthService,
      socket_url: `${wsURL}/messages/`,
    }),
    NgxMaskModule.forRoot()

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ReportModalComponent],
})
export class AppModule { }
