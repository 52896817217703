import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {Doctor} from "donor-tracking-library";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-doctor-details',
  templateUrl: './doctor-details.component.html',
  styleUrls: ['./doctor-details.component.scss']
})
export class DoctorDetailsComponent implements OnInit {

  doctor: Doctor;

  constructor(private route: ActivatedRoute,
              private location: Location) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.doctor = JSON.parse(params['doctor']);
    });
  }

  goBack(): void {
    this.location.back();
  }

}
