import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {apiURL} from "../settings";
import {HttpEventType, HttpEvent} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BloodTestResultService {

  constructor(private http: HttpClient) { }

  uploadPrivateFiles(files, donor_id) {
    return this.uploadFile(files, true, donor_id)
  }

  uploadPublicFiles(files, donor_id) {
    return this.uploadFile(files, false, donor_id)
  }

  uploadFile(filesToUpload: File[], is_private, donor_id): Observable<any> {
    const uploadFileUrl = `${apiURL}/test-results/blood/`;
    const formData: FormData = new FormData();
    formData.append('donor', donor_id);
    formData.append('is_private', is_private);

    for (let file of filesToUpload) {
      formData.append('file', file, file.name);
    }

    return this.http.post(uploadFileUrl, formData, {
      reportProgress: true,
      observe: 'events',
    }).pipe(
      map((event: HttpEvent<any>) => this.getEventMessage(event))
    );
  }

  private getEventMessage(event: HttpEvent<any>) {

    switch (event.type) {

      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);

      case HttpEventType.Response:
        return this.apiResponse(event);

      default:
        return '';
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  getTestResults(donor_id) {
    const uploadedFilesUrl = `${apiURL}/test-results/blood/`;
    return this.http.get(uploadedFilesUrl);
  }
}
