import {Injectable} from '@angular/core';

import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {StaffAuthResponse, StaffData} from "donor-tracking-library";
import {apiURL, authTokenStoreName} from "../settings";
import {map, catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {StaffService} from "../staff/staff.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
              private router: Router,
              private staffService: StaffService) {
  }

  userData: StaffData;
  userRole;

  login(credentials): Observable<any> {
    const loginURL = `${apiURL}/auth/login/`;

    return this.http.post(loginURL, {
      email: credentials.email,
      password: credentials.password
    }).pipe(map((resp: StaffAuthResponse) => {
      this.setToken(resp.token);
      this.userData = resp.user_data as StaffData;
      this.userRole = resp.user_role;
      return resp;
    }));
  }

  private setToken(token: string) {
    localStorage.setItem(authTokenStoreName, token);
  }

  forgotPassword(email: string) {
    const forgotPasswordURL = `${apiURL}/auth/forgot-password/`;

    return this.http.post(forgotPasswordURL, {email});
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return token && token.length != 0;
  }

  getToken(): string {
    return localStorage.getItem(authTokenStoreName);
  }

  logout() {
    const logoutUrl = `${apiURL}/auth/logout/`;

    if (this.loggedInBefore()) {
      this.http.post(logoutUrl, {}).subscribe({
        next: (resp) => {
          this.doUnauthorizedStuff();
        },
        error: (err) => console.log
      });
    }
    else
      this.doUnauthorizedStuff()
  }

  loggedInBefore(): Observable<any> {
    const userObservable = this.staffService.getStaffData();

    return userObservable.pipe(
      map((resp: StaffAuthResponse) => {
        this.userData = resp.user_data as StaffData;
        this.userRole = resp.user_role;
        return !!resp
      }),
      catchError((err) => of(false))
    );
  }


  doUnauthorizedStuff(afterLoginRedirect=null) {
    localStorage.removeItem(authTokenStoreName);
    this.router.navigate(['/login'], {
      queryParams: {
        return_path: afterLoginRedirect
      }
    });
  }

}
