import {Component, OnInit} from '@angular/core';
import {DonorService} from "../donor.service";
import {HostListener} from "@angular/core";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { Observable, combineLatest, BehaviorSubject } from "rxjs";
import {
  Donor,
  ORGAN_CHOICES,
  BLOOD_TEST_STATUS_CHOICES,
  DONOR_STATUS_CHOICES,
  DONOR_STATUS_DEFAULT_CHOICE, MessagingService
} from "donor-tracking-library";

export interface Donor$ extends Donor {
  unread_messages_count$: Observable<number>
}

@Component({
  selector: 'app-donor-list',
  templateUrl: './donor-list.component.html',
  styleUrls: ['./donor-list.component.scss']
})
export class DonorListComponent implements OnInit {

  tableColumns: string[] = ['last_name', 'first_name', 'birth_date', 'phone', 'organ', 'created_at', 'blood_test_status', 'days_since',
    'nurse', 'assistant', 'status', 'chat', 'edit'];

  donors$ = new BehaviorSubject<Donor$[]>([]);

  unReadCountsArray$: Observable<any[]>;

  isLoading: boolean = false;

  DONOR_STATUS_DEFAULT_CHOICE = DONOR_STATUS_DEFAULT_CHOICE;

  private lastNameSearchTerms = new BehaviorSubject<string>('');
  private firstNameSearchTerms = new BehaviorSubject<string>('');
  private birthDateSearchTerms = new BehaviorSubject<string>('');
  private phoneSearchTerms = new BehaviorSubject<string>('');
  private organSearchTerms = new BehaviorSubject<string>('');
  private createdAtSearchTerms = new BehaviorSubject<string>('');
  private nurseSearchTerms = new BehaviorSubject<string>('');
  private assistantSearchTerms = new BehaviorSubject<string>('');
  private donorStatusSearchTerms = new BehaviorSubject<string>('');
  private bloodTestStatusSearchTerms = new BehaviorSubject<string>('');
  private daysSearchTerm = new BehaviorSubject<string>('');

  lastNameSearch$: Observable<any>;
  firstNameSearch$: Observable<any>;
  birthDateSearch$: Observable<any>;
  phoneSearch$: Observable<any>;
  organSearch$: Observable<any>;
  createdAtSearch$: Observable<any>;
  nurseSearch$: Observable<any>;
  assistantSearch$: Observable<any>;
  donorStatusSearch$: Observable<any>;
  bloodTestStatusSearch$: Observable<any>;
  daysSearch$: Observable<any>;

  sortObject$ = new BehaviorSubject<any>(null);

  filtersObject: any;

  constructor(private donorService: DonorService, private messageService: MessagingService) { }

  ngOnInit() {
    this.messageService.startLiveChat();
    this.unReadCountsArray$ = this.messageService.unReadMsgsCountsArray;
    this.lastNameSearch$ = this.lastNameSearchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),
      // ignore new term if same as previous term
      distinctUntilChanged()
    );

    this.firstNameSearch$ = this.firstNameSearchTerms.pipe(
      debounceTime(300), distinctUntilChanged()
    );

    this.birthDateSearch$ = this.birthDateSearchTerms.pipe(
      debounceTime(300), distinctUntilChanged()
    );

    this.phoneSearch$ = this.phoneSearchTerms.pipe(
      debounceTime(300), distinctUntilChanged()
    );

    this.organSearch$ = this.organSearchTerms.pipe(
      debounceTime(300), distinctUntilChanged()
    );

    this.createdAtSearch$ = this.createdAtSearchTerms.pipe(
      debounceTime(300), distinctUntilChanged()
    );

    this.nurseSearch$ = this.nurseSearchTerms.pipe(
      debounceTime(300), distinctUntilChanged()
    );

    this.assistantSearch$ = this.assistantSearchTerms.pipe(
      debounceTime(300), distinctUntilChanged()
    );

    this.donorStatusSearch$ = this.donorStatusSearchTerms.pipe(
      debounceTime(300), distinctUntilChanged()
    );

    this.bloodTestStatusSearch$ = this.bloodTestStatusSearchTerms.pipe(
      debounceTime(300), distinctUntilChanged()
    );

    this.daysSearch$ = this.daysSearchTerm.pipe(
      debounceTime(300), distinctUntilChanged()
    );

    combineLatest(this.lastNameSearch$, this.firstNameSearch$, this.birthDateSearch$, this.phoneSearch$,
      this.organSearch$, this.createdAtSearch$, this.nurseSearch$, this.assistantSearch$, this.donorStatusSearch$, this.bloodTestStatusSearch$,
      this.daysSearch$, this.sortObject$)
      .subscribe(([lastNameTerm, firstNameTerm, birthDateTerm, phoneTerm, organTerm, createdAtTerm, nurseTerm,
        assistantTerm, donorStatusTerm, bloodTestStatusTerm, daysTerm, sortObject]) => {
        this.filtersObject = {
          last_name: lastNameTerm,
          first_name: firstNameTerm,
          birth_date: birthDateTerm,
          phone: phoneTerm,
          organ: organTerm,
          created_at: createdAtTerm,
          nurse: nurseTerm,
          assistant: assistantTerm,
          status: donorStatusTerm,
          blood_test_status: bloodTestStatusTerm,
          days: daysTerm
        };
        this.getDonorList(0, this.filtersObject, sortObject)
          .subscribe((donors: Donor$[]) => {
            this.donors$.next(donors);
            this.isLoading = false;
          });
      });

    // Initially: filter donors with the default status
    //This update to the BehaviorSubject donorStatusSearchTerms, will force the getDonorList call,
    // so no need to call it explicitly
    this.donorStatusSearchTerms.next(DONOR_STATUS_DEFAULT_CHOICE.value);
  }

  search(field: string, term: string): void {
    if(field === 'last_name')
      this.lastNameSearchTerms.next(term);
    else if(field === 'first_name')
      this.firstNameSearchTerms.next(term);
    else if(field === 'birth_date')
      this.birthDateSearchTerms.next(term);
    else if(field === 'phone')
      this.phoneSearchTerms.next(term);
    else if(field === 'organ')
      this.organSearchTerms.next(term);
    else if(field === 'created_at')
      this.createdAtSearchTerms.next(term);
    else if(field === 'nurse')
      this.nurseSearchTerms.next(term);
    else if(field === 'assistant')
      this.assistantSearchTerms.next(term);
    else if(field === 'status')
      this.donorStatusSearchTerms.next(term);
    else if(field === 'blood_test_status')
      this.bloodTestStatusSearchTerms.next(term);
    else if(field === 'days')
      this.daysSearchTerm.next(term);
  }

  getDonorList(offset=0, filters=null, sortObject=null): Observable<Donor$[]> {
    this.isLoading = true;
    return this.donorService.list(offset, filters, sortObject)
      .pipe(map((donors: Donor[]) => donors.map((donor) => {
        //Use organ name instead of db value
        if(donor.organ)
          donor.organ = ORGAN_CHOICES.find(organ => organ.value === donor.organ).name;
        if(donor.blood_test_status)
          donor.blood_test_status = BLOOD_TEST_STATUS_CHOICES.find(status => status.value === donor.blood_test_status).name;
        if(donor.status)
          donor.status = DONOR_STATUS_CHOICES.find(status => status.value === donor.status).name;

        return {...
          donor,
            unread_messages_count$: this.unReadCountsArray$.pipe(map((objs: any) => {
                let foundedObj = objs.find(obj => obj.sender_user_id === donor.user_id);
                return foundedObj ? foundedObj.count : null
              }
            ))
        }

      })));
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      //TODO: Don't do a request if the last response.next is null
      let offset = this.donors$.getValue().length;
      this.getDonorList(offset, this.filtersObject).subscribe((donors: Donor$[]) => {
        let updatedDonors = this.donors$.getValue().concat(donors);
        this.donors$.next(updatedDonors);
        this.isLoading = false;
      });
    }
  }

  sort(event) {
    //TODO: handle sort field names and directions with a better general way
    if(event.active == 'chat')
      event.active = 'unread_messages_count';
    if(event.active == 'nurse')
      event.active = 'nurse_display_name';
    if(event.active == 'assistant')
      event.active = 'assistant_display_name';
    this.sortObject$.next(event);
  }

  ngOnDestroy() {
    this.messageService.removeOnMessageListener();
  }

}
