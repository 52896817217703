import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import {StaffData} from "../../shared/models";
import {MessageService} from "../../message/message.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') $drawer;
  sidenavMode = 'side';
  resizeSubscription: Subscription;
  resize$ = fromEvent(window, 'resize').pipe(debounceTime(500));

  user: StaffData;
  user_role: string;

  constructor(private authService: AuthService, public router: Router, private messageService: MessageService) {}

  ngOnInit() {
    this.resizeSubscription = this.resize$.subscribe(() => this.resizeHandler());
    this.resizeHandler();
    if (this.sidenavMode === 'side') {
      this.toggleSidenav();
    }

    this.user = this.authService.userData;
    this.user_role = this.authService.userRole;
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }


  toggleSidenav() {
    this.$drawer.toggle();
  }

  resizeHandler() {
    const width = window.innerWidth;
    this.sidenavMode = width > 1100 ? 'side' : 'over';
  }

  onClickLogout() {
    //TODO: Move to doUnauthorizedStuff method in auth service after solving circular dependency
    this.messageService.closeLiveChat();
    this.authService.logout();
  }
}
