import {Component, OnInit} from '@angular/core';
import {Validators, FormBuilder} from "@angular/forms";
import {passwordMinLength} from "../../settings";
import {DonorService} from "../donor.service";
import * as moment from 'moment';
import {MatSnackBar} from "@angular/material";
import { Location } from '@angular/common';
import {ActivatedRoute, Router} from "@angular/router";
import {Donor, ORGAN_CHOICES, GENDER_CHOICES} from "donor-tracking-library";


@Component({
  selector: 'app-donor-create-form',
  templateUrl: './donor-create-form.component.html',
  styleUrls: ['./donor-create-form.component.scss']
})
export class DonorCreateFormComponent implements OnInit{

  creationLoading: boolean;
  creationError: boolean;
  creationErrorObject: any;

  //Edit mode variables
  isEditMode: boolean;
  donorId: string;
  donorOldData: any;

  ORGAN_CHOICES = ORGAN_CHOICES;

  GENDER_CHOICES = GENDER_CHOICES;

  donorForm = this.fb.group({
    public_id: ['', [Validators.pattern('[0-9]{8}'), Validators.minLength(8), Validators.maxLength(8)]],
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    user: this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.minLength(passwordMinLength)]],
    }),
    birth_date: ['', [Validators.required]],
    gender: ['', [Validators.required]],
    phone: ['', [Validators.pattern('[0-9]{10}')]],
    address: ['', []],
    organ: ['', [Validators.required]],
  });

  ngOnInit() {
    if(this.router.url !== '/donor-add') {
      this.isEditMode = true;
      this.donorId = this.route.snapshot.paramMap.get('id');
      this.donorService.details(this.donorId)
        .subscribe((donor: Donor) => {

          this.donorOldData = {
            public_id: donor.public_id,
            first_name: donor.first_name,
            last_name: donor.last_name,
            user: {
              email: donor.email,
              password: ''
            },
            birth_date: donor.birth_date,
            gender: donor.gender,
            phone: donor.phone,
            address: donor.address,
            organ: donor.organ
          };
          this.donorForm.patchValue({
            public_id: donor.public_id,
            first_name: donor.first_name,
            last_name: donor.last_name,
            user: {
              email: donor.email,
              password: ''
            },
            birth_date: donor.birth_date,
            gender: donor.gender,
            phone: donor.phone,
            address: donor.address,
            organ: donor.organ
          });
        });

    }
    else
      this.donorForm.get('user.password').setValidators(Validators.required);
  }

  constructor(private donorService: DonorService,
              private snackBar: MatSnackBar, private router: Router,
              private location: Location, private route: ActivatedRoute,
              private fb: FormBuilder) {
  }

  createOrUpdate(){
    this.creationError = false;
    this.creationErrorObject = null;
    this.creationLoading = true;
    const formValue = this.donorForm.value;
    const donorData = Object.assign(
      {},
      formValue
      , {
        birth_date: moment(formValue.birth_date).format('YYYY-MM-DD'),
      },
    );

    // add valid values for empty optional fields (id is an decimal field in db)
    if(!donorData.public_id)
      donorData.public_id = null;

    if(!this.isEditMode)
      this.donorService.addNewDonor(donorData)
        .subscribe(
          (res) => {
            this.creationLoading = false;
            this.openSnackBar('Donor created successfully!', 'OK')
          },
          (errorRes) => {
            this.creationLoading = false;
            this.creationError = true;
            this.creationErrorObject = errorRes.error;
          }
        );
    else {
      if(!donorData.user.password)
        delete donorData.user.password;
      this.donorService.updateDonor(this.donorId, donorData)
        .subscribe(
          (res) => {
            this.creationLoading = false;
            this.openSnackBar('Donor updated successfully!', 'OK')
          },
          (errorRes) => {
            this.creationLoading = false;
            this.creationError = true;
            this.creationErrorObject = errorRes.error;
          }
        )
    }
  }

  cancel(){
    this.location.back();
  }

  isInfoNotChanged() {
    return JSON.stringify(this.donorForm.value) === JSON.stringify(this.donorOldData)
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    }).afterDismissed().subscribe(() => {
      this.location.back();
    });
  }
}
