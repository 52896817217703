import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {StaffService} from "../staff.service";
import {Assistant} from "donor-tracking-library";

@Component({
  selector: 'app-assistant-list',
  templateUrl: './assistant-list.component.html',
  styleUrls: ['./assistant-list.component.scss']
})
export class AssistantListComponent implements OnInit {

  assistants$: Observable<Assistant[]>;
  tableColumns: string[] = ['display_name', 'email'];

  constructor(private staffService: StaffService) { }

  ngOnInit() {
    this.assistants$ = this.staffService.clinicAssistantsList();
  }

}
