import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {Assistant} from "donor-tracking-library";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-assistant-details',
  templateUrl: './assistant-details.component.html',
  styleUrls: ['./assistant-details.component.scss']
})
export class AssistantDetailsComponent implements OnInit {

  assistant: Assistant;

  constructor(private route: ActivatedRoute,
              private location: Location) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.assistant = JSON.parse(params['assistant']);
    });
  }

  goBack(): void {
    this.location.back();
  }

}
