import { Component, OnInit } from '@angular/core';
import {Validators, FormBuilder} from "@angular/forms";
import {passwordMinLength} from "../../settings";
import * as moment from 'moment';
import {MatSnackBar} from "@angular/material";
import { Location } from '@angular/common';
import {StaffService} from "../staff.service";
import {StaffAuthResponse} from "donor-tracking-library";

// TODO: separate clinic admins, doctors and nurses in 3 different components whenever needed

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  updateLoading: boolean;
  updateError: boolean;
  updateErrorObject: any;
  profileCurrentInfo: any;
  staffRole: string;

  showPass: boolean = false;
  passFieldType: string = 'password';

  profileForm = this.fb.group({
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    user: this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', [Validators.minLength(passwordMinLength)]],
    }),
  });

  constructor(private staffService: StaffService,
              private snackBar: MatSnackBar,
              private location: Location,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.staffService.getStaffData()
      .subscribe((resp: StaffAuthResponse) => {
        this.staffRole = resp.user_role;
        let profile = resp.user_data;
        this.profileCurrentInfo = {
          first_name: profile.first_name,
          last_name: profile.last_name,
          user: {
            email: profile.email,
            password: ''
          },
        };
        this.profileForm.patchValue({
          first_name: profile.first_name,
          last_name: profile.last_name,
          user: {
            email: profile.email,
            password: ''
          }
        });
      });
  };

  update(){
    this.updateError = false;
    this.updateErrorObject = null;
    this.updateLoading = true;
    const formValue = this.profileForm.value;
    if(!formValue.user.password)
      delete formValue.user.password;
    const staffData = Object.assign(
      {},
      formValue
      , {
        birth_date: moment(formValue.birth_date).format('YYYY-MM-DD'),
      }
    );

    let updateMethod;
    if(this.staffRole == 'clinic_admin')
      updateMethod = this.staffService.updateClinicAdminProfile.bind(this.staffService);
    else if(this.staffRole == 'doctor')
      updateMethod = this.staffService.updateDoctorProfile.bind(this.staffService);
    else if(this.staffRole == 'nurse')
      updateMethod = this.staffService.updateNurseProfile.bind(this.staffService);
    else if(this.staffRole == 'assistant')
      updateMethod = this.staffService.updateAssistantProfile.bind(this.staffService);

    updateMethod(staffData)
      .subscribe(
        (res) => {
          this.updateLoading = false;
          this.openSnackBar('Profile updated successfully!', 'OK')
        },
        (errorRes) => {
          this.updateLoading = false;
          this.updateError = true;
          this.updateErrorObject = errorRes.error;
        }
      )
  }

  cancel(){
    this.location.back();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    }).afterDismissed().subscribe(() => {
      this.location.back();
    });
  }

  isInfoNotChanged() {
    return JSON.stringify(this.profileForm.value) === JSON.stringify(this.profileCurrentInfo)
  }

  toggleShowPassword() {
    this.showPass = !this.showPass;
    this.passFieldType = this.showPass ? 'text' : 'password';
  }
}
