import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {apiURL, wsURL} from "../settings";
import {AuthService} from "../auth/auth.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient, private authService: AuthService,) { }

  ws: WebSocket;

  // TODO: make it method argument in chat_with_donor
  MESSAGES_PAGE_SIZE = 40;

  private isWebSocketReconnectingSource = new BehaviorSubject<boolean>(false);
  public isWebSocketReconnecting = this.isWebSocketReconnectingSource.asObservable();

  private messagesUrl = `${apiURL}/message/`;

  chat_with_donor(donor_id, offset): Observable<any> {
    const chatUrl = `${this.messagesUrl}${donor_id}/?limit=${this.MESSAGES_PAGE_SIZE}&offset=${offset}`;
    return this.http.get(chatUrl).pipe(map((resp: any) => {
      return resp.results;
    }))
  }

  send_message(content, donor_id) {
    this.ws.send(JSON.stringify({
      content:content,
      donor_id: donor_id
    }));
  }

  startLiveChat(onMessageCallback) {
    if(!this.ws || this.ws.readyState !== this.ws.OPEN)
      this.initializeAutoReconnectingWebSocket(onMessageCallback);
    else
      this.setWebSocketCallbacks(onMessageCallback);
  }

  initializeAutoReconnectingWebSocket(onMessageCB) {
    if(!this.authService.isAuthenticated()) {
      this.closeLiveChat();
      return;
    }
    this.ws = new WebSocket(`${wsURL}/messages/?token=${this.authService.getToken()}`);
    this.ws.onopen = () => this.isWebSocketReconnectingSource.next(false);
    this.setWebSocketCallbacks(onMessageCB);
  }

  private setWebSocketCallbacks(onMessageCB) {
    this.ws.onmessage = onMessageCB;
    this.ws.onclose = () => {
      // this.authService.loggedInBefore();
      this.isWebSocketReconnectingSource.next(true);
      setTimeout(() => {
        this.initializeAutoReconnectingWebSocket(onMessageCB);
      }, 1000)
    };
    this.ws.onerror = () => {
      this.ws.close();
    };
  }

  removeOnMessageListener() {
    if(this.ws) {
      this.ws.onmessage = null;
    }
  }

  closeLiveChat() {
    if(!this.ws) return;
    this.ws.onclose = (event) => {
      delete this.ws;
    };
    this.ws.close();
  }

  readAll(donor_id) {
    return this.http.patch(`${this.messagesUrl}${donor_id}/read_all/`, {});
  }

  unreadLatest(donor_id) {
    return this.http.patch(`${this.messagesUrl}${donor_id}/unread_latest/`, {});
  }

}
