import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {Nurse} from "donor-tracking-library";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-nurse-details',
  templateUrl: './nurse-details.component.html',
  styleUrls: ['./nurse-details.component.scss']
})
export class NurseDetailsComponent implements OnInit {

  nurse: Nurse;

  constructor(private route: ActivatedRoute,
              private location: Location) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.nurse = JSON.parse(params['nurse']);
    });
  }

  goBack(): void {
    this.location.back();
  }
}
