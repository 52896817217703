import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {apiURL} from "../settings";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ActionTriggerService {

  constructor(private http: HttpClient) { }

  daysSinceTriggerDetails(trigger_id): Observable<any> {
    const triggerDetailsUrl = `${apiURL}/trigger/days-since/${trigger_id}/`;
    return this.http.get(triggerDetailsUrl)
  }

  addDaysSinceTrigger(trigger): Observable<any> {
    const addTriggerUrl = `${apiURL}/trigger/days-since/`;
    return this.http.post(addTriggerUrl, trigger);
  }

  updateDaysSinceTrigger(trigger_id, updated_data): Observable<any> {
    const updateTriggerUrl = `${apiURL}/trigger/days-since/${trigger_id}/`;
    return this.http.patch(updateTriggerUrl, updated_data);
  }

  deleteDaysSinceTrigger(trigger_id): Observable<any> {
    const deleteTriggerUrl = `${apiURL}/trigger/days-since/${trigger_id}/`;
    return this.http.delete(deleteTriggerUrl);
  }

  daysSinceTriggersList(): Observable<any> {
    const triggersListUrl = `${apiURL}/trigger/days-since/`;
    return this.http.get(triggersListUrl);
  }
}
