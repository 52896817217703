import { Component, OnInit } from '@angular/core';
import {ActionTriggerService} from "../action-trigger.service";
import {Observable} from "rxjs";
import {DaysSinceTrigger} from "donor-tracking-library";
import {MatDialog} from "@angular/material";

@Component({
  selector: 'app-days-since-trigger-list',
  templateUrl: './days-since-trigger-list.component.html',
  styleUrls: ['./days-since-trigger-list.component.scss']
})
export class DaysSinceTriggerListComponent implements OnInit {

  triggers$: Observable<DaysSinceTrigger[]>;

  constructor(private triggersService: ActionTriggerService, private dialog: MatDialog) { }

  ngOnInit() {
    this.getAllDaysSinceTriggers();
  }

  getAllDaysSinceTriggers() {
    this.triggers$ = this.triggersService.daysSinceTriggersList();
  }

  deleteTrigger(id) {
    this.triggersService.deleteDaysSinceTrigger(id)
      .subscribe(() => this.getAllDaysSinceTriggers());
  }
}
