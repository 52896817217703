import { Component, Inject } from '@angular/core';
import {Donor} from "donor-tracking-library";
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as jspdf from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss']
})
export class ReportModalComponent {

  viewColumns =  ['display_name', 'organ', 'blood_test_status', 'days_since', 'phone'];
  fileColumns = [
    {title: "Name", dataKey: "display_name"},
    {title: "Organ", dataKey: "organ"},
    {title: "Phone", dataKey: "phone"},
    {title: "Blood test status", dataKey: "blood_test_status"},
    {title: "Days since", dataKey: "days"},
  ];

  constructor(
    public dialogRef: MatDialogRef<ReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public donors: Donor[]) {}

  onNoClick(): void {
    this.dialogRef.close();
    console.log(this.donors);
  }

  createFile() {
    let rows = this.donors;
    let doc = new jspdf('p', 'pt');
    doc.autoTable(this.fileColumns, rows, {
      styles: {fillColor: [100, 255, 255]},
      columnStyles: {
        id: {fillColor: 255}
      },
      margin: {top: 60},
      beforePageContent: function(data) {
        doc.text("Donor Inactivity Report", 40, 30);
      }
    });
    return doc
  }

  print() {
    let doc = this.createFile();
    doc.autoPrint();
    doc.output('dataurlnewwindow');
  }
  downloadPDF() {
    let doc = this.createFile();
    doc.save('Donor Inactivity Report.pdf');
  }

}
