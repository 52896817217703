import {Injectable, Injector} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http'
import {Location} from '@angular/common';
import { Observable } from 'rxjs';
import {AuthService} from "./auth.service";
import {tap, take} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {MessageService} from "../message/message.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  auth: AuthService;
  constructor(private injector: Injector, private location: Location, private route: ActivatedRoute,
              private messageService: MessageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.auth = this.injector.get(AuthService);
    return next.handle(req)
      .pipe(tap(event => {
        return event;
      },
        (err)=>{
          if (err instanceof HttpErrorResponse) {
            if (err instanceof HttpErrorResponse && err.status == 401) {
              this.route.queryParams
                .pipe(take(1))
                .subscribe(params => {
                  let returnPath = params['return_path'] || this.location.path(true);
                  //TODO: Move to doUnauthorizedStuff method in auth service after solving circular dependency
                  this.messageService.closeLiveChat();
                  this.auth.doUnauthorizedStuff(returnPath);
                });
            }
          }
        return err;
      }));
  }
}
