import { Component, OnInit } from '@angular/core';
import {AuthService} from "../auth.service";
import {FormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  loading: boolean;
  successMsg: string;

  constructor(private fb: FormBuilder, private auth: AuthService) { }

  forgotPasswordForm = this.fb.group({
    'email': ['', [Validators.required, Validators.email]],
  });

  forgotPassword() {
    this.loading = true;
    return this.auth.forgotPassword(this.forgotPasswordForm.value.email)
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.successMsg = 'Please check your email to retrieve your password!'
        },
          error: (errorRes) => {
            this.loading = false;
            this.successMsg = 'Please check your email to retrieve your password!'
        },
      })
  }

}
