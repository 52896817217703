import { Component, OnInit } from '@angular/core';
import {StaffService} from "../staff.service";
import {Observable} from "rxjs";
import {Nurse} from "donor-tracking-library";

@Component({
  selector: 'app-nurse-list',
  templateUrl: './nurse-list.component.html',
  styleUrls: ['./nurse-list.component.scss']
})
export class NurseListComponent implements OnInit {

  nurses$: Observable<Nurse[]>;
  tableColumns: string[] = ['display_name', 'email'];

  constructor(private staffService: StaffService) { }

  ngOnInit() {
    this.nurses$ = this.staffService.clinicNursesList();
  }


}
