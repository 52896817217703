import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';

import {AuthService} from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private injector: Injector) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {

    const auth = this.injector.get(AuthService);

    if(auth.isAuthenticated()) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Token ${auth.getToken()}`)
      });
    }
    // if(!request.headers.has('Content-Type')) {
    //   request = request.clone({
    //     headers: request.headers.set('Content-Type', 'application/json')
    //   });
    // }

    return next.handle(request);
  }
}
