import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {apiURL} from "../settings";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {StaffData} from "donor-tracking-library";
// import {AuthService} from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private http: HttpClient) { }

  getStaffData() {
    const profileUrl = `${apiURL}/staff/profile/`;
    return this.http.get(profileUrl)
  }

  addNewDoctor(doctor) {
    const addDoctorUrl = `${apiURL}/staff/doctor/`;
    return this.http.post(addDoctorUrl, doctor);
  }

  addNewNurse(nurse) {
    const addNurseUrl = `${apiURL}/staff/nurse/`;
    return this.http.post(addNurseUrl, nurse);
  }

  addNewAssistant(assistant) {
    const addAssistantUrl = `${apiURL}/staff/assistant/`;
    return this.http.post(addAssistantUrl, assistant);
  }

  clinicAdminsList(): Observable<any> {
    const adminListUrl = `${apiURL}/staff/clinic-admin/`;
    return this.http.get(adminListUrl);
  }

  clinicNursesList(): Observable<any> {
    const nurseListUrl = `${apiURL}/staff/nurse/`;
    return this.http.get(nurseListUrl);
  }

  clinicDoctorsList(): Observable<any> {
    const doctorListUrl = `${apiURL}/staff/doctor/`;
    return this.http.get(doctorListUrl);
  }

  clinicAssistantsList(): Observable<any> {
    const assistantListUrl = `${apiURL}/staff/assistant/`;
    return this.http.get(assistantListUrl);
  }

  updateClinicAdminProfile(updated_data) {
    const profileUrl = `${apiURL}/staff/clinic-admin/`;
    return this.http.patch(profileUrl, updated_data);
    // .pipe(map((staffData: StaffData) => {
    //   this.auth.userData = staffData;
    //   return staffData;
    // }))
  }

  updateDoctorProfile(updated_data) {
    const profileUrl = `${apiURL}/staff/doctor/`;
    return this.http.patch(profileUrl, updated_data);
      // .pipe(map((staffData: StaffData) => {
      //   this.auth.userData = staffData;
      //   return staffData;
      // }))
  }

  updateNurseProfile(updated_data) {
    const profileUrl = `${apiURL}/staff/nurse/`;
    return this.http.patch(profileUrl, updated_data);
    // .pipe(map((staffData: StaffData) => {
    //   this.auth.userData = staffData;
    //   return staffData;
    // }))
  }

  updateAssistantProfile(updated_data) {
    const profileUrl = `${apiURL}/staff/assistant/`;
    return this.http.patch(profileUrl, updated_data);
    // .pipe(map((staffData: StaffData) => {
    //   this.auth.userData = staffData;
    //   return staffData;
    // }))
  }
}
