import { Component, OnInit } from '@angular/core';
import {Validators, FormBuilder} from "@angular/forms";
import {BLOOD_TEST_STATUS_CHOICES, DaysSinceTrigger} from "donor-tracking-library";
import {MatSnackBar} from "@angular/material";
import {Router, ActivatedRoute} from "@angular/router";
import { Location } from '@angular/common';
import {ActionTriggerService} from "../action-trigger.service";
import * as moment from 'moment';

@Component({
  selector: 'app-days-since-trigger-form',
  templateUrl: './days-since-trigger-form.component.html',
  styleUrls: ['./days-since-trigger-form.component.scss']
})
export class DaysSinceTriggerFormComponent implements OnInit {

  donorId: string;

  creationLoading: boolean;
  creationError: boolean;
  creationErrorObject: any;

  //Edit mode variables
  isEditMode: boolean;
  triggerId: string;
  triggerOldData: any;

  BLOOD_STATUS_CHOICES = BLOOD_TEST_STATUS_CHOICES;

  DAYS_SINCE_FILTERING_OPTIONS = [
    {
      name: 'Days Since is equal to',
      value: false // value for is_greater_days_included
    },
    {
      name: 'Days Since is greater than',
      value: true // value for is_greater_days_included
    }
  ];

  triggerForm = this.fb.group({
    required_blood_test_status: ['', [Validators.required]],
    required_days_since: ['', [Validators.required]],
    is_greater_days_included: ['', [Validators.required]],
    content: ['', [Validators.required]],
    start_date: ['', []],
    end_date: ['', []],
    frequency: ['', []],
  });

  constructor(private triggerService: ActionTriggerService,
              private snackBar: MatSnackBar, private router: Router,
              private location: Location, private route: ActivatedRoute,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    if(this.router.parseUrl(this.router.url).root.children['primary'].segments[0].path !== 'days-since-trigger-add') {
      this.isEditMode = true;
      this.triggerId = this.route.snapshot.paramMap.get('id');
      this.triggerService.daysSinceTriggerDetails(this.triggerId)
        .subscribe((trigger: DaysSinceTrigger) => {

          this.triggerOldData = {
            required_blood_test_status: trigger.required_blood_test_status,
            required_days_since: trigger.required_days_since,
            is_greater_days_included: trigger.is_greater_days_included,
            content: trigger.content,
            start_date: trigger.start_date,
            end_date: trigger.end_date,
            frequency: trigger.frequency
          };
          this.triggerForm.patchValue({
            required_blood_test_status: trigger.required_blood_test_status,
            required_days_since: trigger.required_days_since,
            is_greater_days_included: trigger.is_greater_days_included,
            content: trigger.content,
            start_date: trigger.start_date,
            end_date: trigger.end_date,
            frequency: trigger.frequency
          });
        });

    }
  }

  createOrUpdate(){
    this.creationError = false;
    this.creationErrorObject = null;
    this.creationLoading = true;
    let formValue = this.triggerForm.value;
    formValue = Object.assign(
      {},
      formValue
      , {
        start_date: formValue.start_date? moment(formValue.start_date).format('YYYY-MM-DD'): null,
        end_date: formValue.end_date? moment(formValue.end_date).format('YYYY-MM-DD'): null,
        frequency: formValue.frequency? formValue.frequency : null,
      },
    );

    if(!this.isEditMode) {
      this.triggerService.addDaysSinceTrigger(formValue)
        .subscribe(
          (res) => {
            this.creationLoading = false;
            this.openSnackBar('Trigger created successfully!', 'OK')
          },
          (errorRes) => {
            this.creationLoading = false;
            this.creationError = true;
            this.creationErrorObject = errorRes.error;
          }
        );
    }

    else {
      this.triggerService.updateDaysSinceTrigger(this.triggerId, formValue)
        .subscribe(
          (res) => {
            this.creationLoading = false;
            this.openSnackBar('Trigger updated successfully!', 'OK')
          },
          (errorRes) => {
            this.creationLoading = false;
            this.creationError = true;
            this.creationErrorObject = errorRes.error;
          }
        )
    }
  }

  cancel(){
    this.location.back();
  }

  isInfoNotChanged() {
    return JSON.stringify(this.triggerForm.value) === JSON.stringify(this.triggerOldData)
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    }).afterDismissed().subscribe(() => {
      this.location.back();
    });
  }
}

