import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {AuthService} from "../auth.service";
import {passwordMinLength} from "../../settings";
import {Router, ActivatedRoute} from "@angular/router";
import {take} from "rxjs/operators";
import { staffWebAppVersion } from '../../../../../../package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  appVersion: string = staffWebAppVersion;

  loginLoading: boolean;
  loginError: boolean;
  errorMessage: string;

  showPass: boolean = false;
  passFieldType: string = 'password';

  returnPath: string = '';


  constructor(private fb: FormBuilder, private auth: AuthService, private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams
      .pipe(take(1))
      .subscribe(params => this.returnPath = params['return_path'] || '/donor-list');
  }

  loginForm = this.fb.group({
    'email': ['', [Validators.required, Validators.email]],
    'password': ['', [Validators.required, Validators.minLength(passwordMinLength)]],
  });


  getErrorMessage(controllerName: string) {
    const { errors } = this.loginForm.controls[controllerName];
    return errors.required
      ? `${controllerName} is required`
      : `Please enter a valid ${controllerName}`;
  }

  login() {
    this.loginLoading = true;
    this.loginError = false;
    this.errorMessage = null;
    return this.auth.login(this.loginForm.value).subscribe({
      next: (res) => {
        this.router.navigateByUrl(this.returnPath);
        this.loginLoading = false;
      },
      error: (errorRes) => {
        this.loginError = true;
        this.loginLoading = false;
        this.errorMessage = errorRes.error;
      },
    });
  }

  goToForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  toggleShowPassword() {
    this.showPass = !this.showPass;
    this.passFieldType = this.showPass ? 'text' : 'password';
  }

}
