import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ILink, sidenavLinks } from './sidenav-links';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {
  @Input() type = 'side';
  @Input() user: any;
  @Input() user_role: string;
  @Input() activeRoute: string | string[];
  @Output() logout = new EventEmitter();
  @Output() toggle = new EventEmitter();
  routes: ILink[] = sidenavLinks;

  constructor(private route: ActivatedRoute) { }


  onClickLogout() {
    this.logout.emit();
  }

  onRouting() {
    this.toggle.emit()
  }

  isRouteActive(url) {
    const isString = typeof url === 'string';
    return (isString)
        ? url === this.activeRoute
        : url.join('') === this.activeRoute;
  }
}
