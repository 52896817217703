import { Component, OnInit, Input } from '@angular/core';
import {Donor, MessagingService, ORGAN_CHOICES, BLOOD_TEST_STATUS_CHOICES, DONOR_STATUS_CHOICES} from "donor-tracking-library";
import {DonorService} from "../donor.service";
import {ActivatedRoute} from "@angular/router";
import { Location } from '@angular/common';
import {BloodTestResultService} from "../../blood-test-result/blood-test-result.service";
import {map, tap, finalize} from "rxjs/operators";
import {StaffService} from "../../staff/staff.service";
import {Observable, Subscription, zip} from "rxjs";
import {MatSnackBar} from "@angular/material";

export interface Donor$ extends Donor {
  unread_messages_count$?: Observable<number>
}

@Component({
  selector: 'app-donor-details',
  templateUrl: './donor-details.component.html',
  styleUrls: ['./donor-details.component.scss']
})
export class DonorDetailsComponent implements OnInit {
  @Input() donor: Donor$;

  publicFilesToUpload: File[] =[];
  privateFilesToUpload: File[] =[];
  publicFileUploadEvent: any = {status: '', message: '', filePath: ''};
  privateFileUploadEvent: any = {status: '', message: '', filePath: ''};

  isAddingNote: boolean;
  newNoteContent: string;
  noteIsInEditMode = {}; // the key will be the note id, and the value would be true (in edit) or false
  noteContentInEditMode = {}; // the key will be the note id, and the value would be edited content

  nurses$ :Observable<any>;
  assignedNurse: any;
  selectedNurse: any;
  nurseSubmissionSubscription: Subscription;

  doctors$ :Observable<any>;
  assignedDoctor: any;
  selectedDoctor: any;
  doctorSubmissionSubscription: Subscription;

  assistants$ :Observable<any>;
  assignedAssistant: any;
  selectedAssistant: any;
  assistantSubmissionSubscription: Subscription;

  admins$ :Observable<any>;

  allStaff$: any;
  toBeNotifiedUserId: number;

  DONOR_STATUS_CHOICES = DONOR_STATUS_CHOICES;
  selectedDonorStatus: any;
  donorStatusSubscription: Subscription;

  BLOOD_TEST_STATUS_CHOICES = BLOOD_TEST_STATUS_CHOICES;
  selectedBloodTestStatus: any;
  bloodTestStatusSubscription: Subscription;

  publicTestResults = [];
  privateTestResults = [];

  unReadCountsArray$: Observable<any[]>;

  constructor(private donorService: DonorService,
              private staffService: StaffService,
              private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private location: Location,
              private bloodTestService: BloodTestResultService,
              private messageService: MessagingService) {}

  ngOnInit() {
    this.messageService.startLiveChat();
    this.unReadCountsArray$ = this.messageService.unReadMsgsCountsArray;
    this.getDonorDetails();
    this.nurses$ = this.getClinicNurses();
    this.doctors$ = this.getClinicDoctors();
    this.assistants$ = this.getClinicAssistants();
    this.admins$ = this.getClinicAdmins();
    this.allStaff$ = zip(this.nurses$, this.doctors$, this.assistants$, this.admins$).pipe(
      map(([nurses, doctors, assistants, admins]: any[]) => nurses.concat(doctors, assistants, admins))
    );
  }

  getDonorDetails(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.donorService.details(id)
      .pipe(map((donor: Donor) => {
        if(donor.nurse_details)
          {
            donor.nurse_details.text = donor.nurse_details.display_name;
            this.assignedNurse = [donor.nurse_details]
          }
        else this.assignedNurse = null;

        if(donor.doctor_details)
        {
          donor.doctor_details.text = donor.doctor_details.display_name;
          this.assignedDoctor = [donor.doctor_details]
        }
        else this.assignedDoctor = null;

        if(donor.assistant_details)
        {
          donor.assistant_details.text = donor.assistant_details.display_name;
          this.assignedAssistant = [donor.assistant_details]
        }
        else this.assignedAssistant = null;

        this.selectedDonorStatus = donor.status;

        this.selectedBloodTestStatus = donor.blood_test_status;

        //Use organ name instead of db value
        if(donor.organ)
          donor.organ = ORGAN_CHOICES.find(organ => organ.value === donor.organ).name;

        if(donor.blood_test_results) {
          this.publicTestResults = donor.blood_test_results.filter(result => !result.is_private);
          this.privateTestResults = donor.blood_test_results.filter(result => result.is_private);
        }

          return {...
              donor,
            unread_messages_count$: this.unReadCountsArray$.pipe(map((objs: any) => {
                let foundedObj = objs.find(obj => obj.sender_user_id === donor.user_id);
                return foundedObj ? foundedObj.count : null
              }
            ))
          }
      }),
      tap(() => {
        // if there is an element in url to scroll to
        let elementToScrollId = this.location.path(true).split('#')[1];
        setTimeout(() => {
          const element = document.getElementById(elementToScrollId);
          if (element) {
            const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
            const yOffset = -80;

            window.scrollTo({
              top: yCoordinate + yOffset,
              behavior: 'smooth'
            });
          }
        });
      }))
      .subscribe((donor: Donor$) => this.donor = donor);
  }

  getClinicAdmins(): Observable<any> {
    return this.staffService.clinicAdminsList()
      .pipe(map((list: any[]) => {
          return list.map(admin => {
            return {
              user_id: admin.user_id,
              id: admin.id,
              text: admin.display_name
            };
          })
        })
      )
  }

  getClinicNurses(): Observable<any> {
    return this.staffService.clinicNursesList()
      .pipe(map((list: any[]) => {
          return list.map(nurse => {
            return {
              user_id: nurse.user_id,
              id: nurse.id,
              text: nurse.display_name
            };
          })
        })
      )
  }

  getClinicDoctors(): Observable<any> {
    return this.staffService.clinicDoctorsList()
      .pipe(map((list: any[]) => {
          return list.map(doctor => {
            return {
              user_id: doctor.user_id,
              id: doctor.id,
              text: doctor.display_name
            };
          })
        })
      )
  }

  getClinicAssistants(): Observable<any> {
    return this.staffService.clinicAssistantsList()
      .pipe(map((list: any[]) => {
          return list.map(assistant => {
            return {
              user_id: assistant.user_id,
              id: assistant.id,
              text: assistant.display_name
            };
          })
        })
      )
  }

  handlePublicFileInput(files: FileList) {
    console.log(files);
    this.publicFilesToUpload = [];
    for (var i = 0; i < files.length; i++) {
      this.publicFilesToUpload.push(files.item(i));
    }
  }

  handlePrivateFileInput(files: FileList) {
    console.log(files);
    this.privateFilesToUpload = [];
    for (var i = 0; i < files.length; i++) {
      this.privateFilesToUpload.push(files.item(i));
    }
  }

  uploadPublicResultsFiles() {
    this.bloodTestService.uploadPublicFiles(this.publicFilesToUpload, this.donor.id)
      .pipe(finalize(() => {
        this.getDonorDetails();
        this.publicFilesToUpload = []
      }))
      .subscribe(
        (res: any) => {
          this.publicFileUploadEvent = res;
        }, error => {
          console.log(error);
        });
  }

  uploadPrivateResultsFiles() {
    this.bloodTestService.uploadPrivateFiles(this.privateFilesToUpload, this.donor.id)
      .pipe(finalize(() => {
        this.getDonorDetails();
        this.privateFilesToUpload = []
      }))
      .subscribe(
      (res: any) => {
        this.privateFileUploadEvent = res;
      }, error => {
        console.log(error);
      });
  }

  goBack(): void {
    this.location.back();
  }

  selectNurse(value:any):void {
    this.selectedNurse = value;
  }
  selectDoctor(value:any):void {
    this.selectedDoctor = value;
  }
  selectAssistant(value:any):void {
    this.selectedAssistant = value;
  }

  submitNurseAssign() {
    this.nurseSubmissionSubscription = this.donorService.assignNurseToDonor(this.donor.id, this.selectedNurse.id)
      .subscribe((donor: Donor) => {
        this.openSnackBar('Changes have been saved', 'OK');
        this.donor = donor;
        if (donor.nurse_details) {
          donor.nurse_details.text = donor.nurse_details.display_name;
          this.assignedNurse = [donor.nurse_details];
          this.selectedNurse = null;
        }
      })
  }

  submitDoctorAssign() {
    this.doctorSubmissionSubscription = this.donorService.assignDoctorToDonor(this.donor.id, this.selectedDoctor.id)
      .subscribe((donor: Donor) => {
        this.openSnackBar('Changes have been saved', 'OK');
        this.donor = donor;
        if (donor.doctor_details) {
          donor.doctor_details.text = donor.doctor_details.display_name;
          this.assignedDoctor = [donor.doctor_details];
          this.selectedDoctor = null;
        }
      })
  }

  submitAssistantAssign() {
    this.assistantSubmissionSubscription = this.donorService.assignAssistantToDonor(this.donor.id, this.selectedAssistant.id)
      .subscribe((donor: Donor) => {
        this.openSnackBar('Changes have been saved', 'OK');
        this.donor = donor;
        if (donor.assistant_details) {
          donor.assistant_details.text = donor.assistant_details.display_name;
          this.assignedAssistant = [donor.assistant_details];
          this.selectedAssistant = null;
        }
      })
  }

  SubmitDonorStatusUpdate() {
    this.donorStatusSubscription = this.donorService.changeDonorStatus(this.donor.id, this.selectedDonorStatus)
      .subscribe((donor: Donor) => {
        this.openSnackBar('Changes have been saved', 'OK');
        this.donor = donor;
      })
  }

  SubmitBloodTestStatusUpdate() {
    this.bloodTestStatusSubscription = this.donorService.changeBloodTestStatus(this.donor.id, this.selectedBloodTestStatus)
      .subscribe((donor: Donor) => {
        this.openSnackBar('Changes have been saved', 'OK');
        this.donor = donor;
      })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  addNote() {
    this.donorService.addNote(this.donor.id, this.newNoteContent, this.toBeNotifiedUserId)
      .subscribe((note: any) => {
        this.openSnackBar('Note added successfully', 'OK');
        this.isAddingNote = false;
        this.getDonorDetails();
        this.newNoteContent = null;
        this.toBeNotifiedUserId = null;
      });
  }
  updateNote(note_id, content) {
    this.donorService.updateNote(note_id, content)
      .subscribe((updatedNote: any) => {
        this.openSnackBar('Note updated successfully', 'OK');
        this.noteIsInEditMode[updatedNote.id] = false;
        this.noteContentInEditMode[updatedNote.id] = updatedNote.content;
        this.getDonorDetails();
      });
  }

  // TODO: Workaround to show the unread message badge with a number (1), decide what to do instead!
  unreadLatestMessage() {
    this.messageService.unreadLatest(this.donor.id)
      .subscribe(() => this.getDonorDetails());
  }

  readAllMessages() {
    this.messageService.readAll(this.donor.id)
      .subscribe(() => this.getDonorDetails());
  }

  ngOnDestroy() {
    this.messageService.removeOnMessageListener();

    if(this.nurseSubmissionSubscription)
      this.nurseSubmissionSubscription.unsubscribe();
    if(this.doctorSubmissionSubscription)
      this.doctorSubmissionSubscription.unsubscribe();
    if(this.assistantSubmissionSubscription)
      this.assistantSubmissionSubscription.unsubscribe();
  }
}
